import React from "react";
import {Typography } from "@mui/material";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import {graphql} from "gatsby";

export default function RODOPage() {
  return (
    <SiteLayout>
      <Section>
        <div style={{marginTop: "4rem", textAlign: "center"}}>
          <Typography variant="h2" style={{marginBottom: "1rem"}}>
            Lista podmiotów medycznych, z którymi współpracuje DokDok sp. z o.o. w ramach realizowania świadczeń
            zdrowotnych
          </Typography>
          <div style={{marginBottom: "1rem"}}>
            <Typography variant="h3">
              Badania laboratoryjne
            </Typography>
            <Typography align="center">
              Synevo sp. z o.o. z siedzibą w Warszawie,<br/>
              ul. Zamieniecka 80 / 401, 04-158 Warszawa,<br/>
              <a href="https://www.synevo.pl/" target="_blank">https://www.synevo.pl/</a>
            </Typography>
          </div>
          <div style={{marginBottom: "1rem"}}>
            <Typography variant="h3">
              Badania RTG, USG i spirometria
            </Typography>
            <Typography align="center">
              EMC Instytut Medyczny S.A. z siedzibą we Wrocławiu (54-144), <br/>
              przy ul. Pilczyckiej 144-148, <br/>
              <a href="https://www.emc-sa.pl/" target="_blank">https://www.emc-sa.pl/</a>
            </Typography>
          </div>
          <div style={{marginBottom: "1rem"}}>
            <Typography variant="h3">
              Transport sanitarny
            </Typography>
            <Typography align="center">
              Triage Sp. z o.o. z siedzibą we Wrocławiu (53-033),<br/>
              ul. Zwycięska 35/20, <br/>
              <a href="https://triage.pl/" target="_blank">https://triage.pl/</a>
            </Typography>
          </div>
        </div>
      </Section>
    </SiteLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
